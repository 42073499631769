import { Button, Container, Grid, LinearProgress, Paper, TextField } from "@mui/material";
import React from "react";
import { Api } from "../../consts/api";
import SnakBar from "../others/massg";
import ProductManager from "./manageProducts";


export default function Products(){
    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [refresh, setRefresh] = React.useState(Math.random())
    const handleChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let valid = true
        let err = {}

        if(!formData.name){
            valid = false
            err['name'] = true
        }
        if(!formData.price){
            valid = false
            err['price'] = true
        }
        if(!formData.stock){
            valid = false
            err['stock'] = true
        }

        setErr(err)
        return valid
    }

     const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
            fetch(Api+'addProduct',{
                method : 'POST',
                body : JSON.stringify(formData)
            })
            .then(res=>res.json())
            .then(res=>{
                setLoading(false)
                setRefresh(Math.random())
                if(res.code === 200){
                    setMassg({
                        open : true,
                        severity : 'success',
                        massg : res.massg
                    })
                }else {
                    setMassg({
                        open : true,
                        severity : 'error',
                        massg : res.massg
                    })
                }
            })
            .catch(err=>{
                setLoading(false)
                setMassg({
                    open : true,
                    severity : 'error',
                    massg : "Faild to connect to the server"
                })
            })
        }

     }
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Paper variant="outlined" style={{padding: 10}}>
                        <h3>Add Product</h3>
                        <hr />
                        {loading ? <LinearProgress /> : ''}
                        <TextField
                            variant="outlined"
                            name="name"
                            label="Product Name"
                            fullWidth
                            value={formData.name}
                            error={err.name}
                            onChange = {handleChange}
                            required
                            style={{marginTop: 10}}
                            size="small"
                        />
                        <TextField
                            variant="outlined"
                            name="price"
                            label="price"
                            fullWidth
                            value={formData.price}
                            error={err.price}
                            onChange = {handleChange}
                            required
                            number
                            style={{marginTop: 10}}
                            size="small"
                            type='number'
                        />
                        <TextField
                            variant="outlined"
                            name="stock"
                            label="Stock"
                            fullWidth
                            value={formData.stock}
                            error={err.stock}
                            onChange = {handleChange}
                            required
                            style={{marginTop: 10}}
                            size="small"
                            type='number'
                        />
                        <TextField
                            variant="outlined"
                            name="hsn"
                            label="HSN"
                            fullWidth
                            value={formData.hsn}
                            error={err.hsn}
                            onChange = {handleChange}
                            
                            style={{marginTop: 10}}
                            size="small"
                        />
                        <TextField
                            variant="outlined"
                            name="unit"
                            label="Unit"
                            fullWidth
                            value={formData.unit}
                            error={err.unit}
                            onChange = {handleChange}
                            
                            style={{marginTop: 10}}
                            size="small"
                            helperText="Ex: pcs, kg, Dozen,  "
                        />
                        <Button variant="contained" onClick={onSubmit} disabled={loading} style={{marginTop : 15}} fullWidth color="primary">
                            Save Product
                        </Button>

                        
                       
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <ProductManager refresh={refresh} setRefresh={setRefresh} />
                </Grid>
            </Grid>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}