import React from 'react'
import NumToText from 'number2text'


export default function Pdf(props){
    let dated = new Date(props.info.date)
    let amount = 0
    let tot_amount = 0
    
    return(
        <div>
            <h3 className='head'>TAX INVOICE</h3>
            <table className='table'>
                
                <tr>
                    <td className='td' rowSpan={2} colSpan={2}>
                        <b>MAA RUHINI ENTERPRISE</b><br/>
                        Kulhati Bazar chowk (Damdama) 
                        (Guwahati-Hajo, Nalbari Road) 
                        Kamrup(Assam), Pin- 781104<br/>
                        GSTIN/UIN:18BTRPD4694K1Z4<br/>
                        state name: Assam<br/>
                    </td>
                    <td className='td' colSpan={3}>
                        Invoice NO<br/>
                        <b>{props.invoice_no}</b>
                    </td>
                    <td className='td' colSpan={4} >
                        Dated<br/>
                        <b>{dated.toDateString()}</b>
                    </td>
                </tr>
                <tr>
                    <td className='td' colSpan={3}>
                        Delivery Note <br />
                        {props.info.del_note}
                    </td>
                    <td className='td' colSpan={4}>
                        Mode/Terms of payment
                        <br />
                        {props.info.mode}
                    </td>
                </tr>
                <tr>
                    <td className='td' rowSpan={5} colSpan={2} >
                        Buyer <br />
                        <b>{props.info.buyer_name}</b><br />
                        {props.info.buyer_description}
                        


                    </td>
                    <td className='td' colSpan={3}>
                        Supplier Ref <br />
                        {props.info.supplier_ref}

                    </td>
                    <td className='td' colSpan={4}>
                        Other Reference(s) <br />
                        {props.info.other_ref}
                    </td>
                </tr>
                <tr>
                    
                    <td className='td' colSpan={3}>
                        Buyers Order No <br />
                        {props.info.buyer_order_no}
                    </td>
                    <td className='td' colSpan={4}>
                        Dated <br />
                        {props.info.d_date}
                    </td>
                </tr>
                <tr>
                    
                    <td className='td' colSpan={3}>
                        Dispacth Document No <br />
                        {props.info.dis_document_no}
                    </td>
                    <td className='td' colSpan={4}>
                        Delivery Note Date <br />
                        {props.info.dis_note_date}
                    </td>
                </tr>
                <tr>
                    
                    <td className='td' colSpan={3}>
                        Dispatched through <br />
                        {props.info.dis_through}
                    </td>
                    <td className='td' colSpan={4}>
                        Destination <br />
                        {props.info.destination}
                    </td>
                </tr>
                <tr>
                    
                    <td className='td'  colSpan={7}>
                        Terms of Delivery <br />
                        {props.info.t_o_d}
                    </td>
                    
                </tr>
                <tr>
                    <td className='td' id='sl' >
                        SL No
                    </td>
                    <td className='td' id='description'>
                        Description of Goods
                    </td>
                    <td className='td'>
                        HSN/SAC
                    </td>
                    <td className='td'>
                        GST
                    </td>
                    <td className='td'>
                        Quantity
                    </td>
                    <td className='td'>
                        Rate
                    </td>
                    <td className='td'>
                        per
                    </td>
                    <td className='td'>
                        Disc %
                    </td>
                    <td className='td'>
                        Amount
                    </td>
                </tr>


                {/* need to map */}
                {
                    props.item.map((item,index)=>{
                            amount = Number(item.rate) * Number(item.qty) 
                            if(item.disc > 0){
                                let disc = amount * 1/100
                                amount = amount - disc
                            }

                            tot_amount += amount

                            
                        return(
                            <tr key={index}>
                                <td className='td' id="no-border">
                                    {index+1}
                                </td>
                                <td className='td' id="no-border">
                                    <strong>{item.name}</strong><br />
                                    {item.description}
                                </td>
                                <td className='td' id="no-border">
                                    {item.hsn}
                                </td>
                                <td className='td' id="no-border">
                                    {item.gst_rate}%
                                </td>
                                <td className='td' id="no-border">
                                    {item.qty}
                                </td>
                                <td className='td' id="no-border">
                                    {item.rate}
                                </td>
                                <td className='td' id="no-border">
                                    {item.pcs} 
                                </td>
                                <td className='td' id="no-border">
                                    {item.disc} {!item.disc ? '' : '%'}
                                </td>
                                <td className='td' id="no-border">
                                    {amount}
                                </td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border-gst">
                       <strong> Output CGST @ {props.info.cgst} % </strong>
                    </td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border-gst">
                         {props.info.cgst} %
                    </td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border">
                        <strong>{tot_amount * (Number(props.info.cgst) / 100)}</strong>
                    </td>


                </tr>
                <tr>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border-gst">
                       <strong> Output SGST @ {props.info.sgst} % </strong>
                    </td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border-gst">
                         {props.info.sgst} %
                    </td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border">
                        <strong>{tot_amount * (Number(props.info.sgst) / 100)}</strong>
                    </td>


                </tr>
                <tr>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border-gst">
                       <strong> Round off</strong>
                    </td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border-gst">
                        
                    </td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border"></td>
                    <td className='td' id="no-border">
                        
                    </td>


                </tr>
                <tr>
                    <td className='td' >
                        
                    </td>
                    <td className='td' id="right">
                        <strong>Total</strong>
                    </td>
                    <td className='td'>
                        
                    </td>
                    <td className='td'>
                        
                    </td>
                    <td className='td'>
                        
                    </td>
                    <td className='td'>
                        
                    </td>
                    <td className='td'>
                        
                    </td>
                    <td className='td'>
                        
                    </td>
                    <td className='td'>
                        <strong>{tot_amount+((tot_amount  * ((Number(props.info.cgst) / 100))+(tot_amount * (Number(props.info.sgst) / 100))))}</strong>
                    </td>
                </tr>
                <tr>
                    <td className='td' colSpan={9}>
                        Amount chargeble(in words) <br />
                        <strong>{NumToText(tot_amount+((tot_amount * ((Number(props.info.cgst) / 100))+(tot_amount * (Number(props.info.sgst) / 100)))),'',true)}</strong>
                    </td>
                </tr>
                <tr>
                    <td className='td' colSpan={3} rowSpan={2}>
                        HSN/SAC
                    </td>
                    <td className='td' rowSpan={2}>
                        Texable Value
                    </td>
                    <td className='td' colSpan={2}>
                        Central Tax
                    </td>
                    <td className='td' colSpan={2}>
                        State Tax
                    </td>
                    <td className='td' rowSpan={2}>
                        Total
                        Tax Amount
                    </td>
                </tr>
                <tr>
                     
                   <td className='td'>Rate</td>
                   <td className='td'>Amount</td>
                   <td className='td'>Rate</td>
                   <td className='td'>Amount</td>

                </tr>
                <tr>
                    <td className='td' colSpan={3} >
                    <div id="hsn">
                        {
                            props.item.map((item,index)=>
                                <div key={index}>{item.hsn},</div>
                            )
                        }
                    </div>
                    </td>
                    <td className='td'>{tot_amount}</td>
                    <td className='td'>{props.info.cgst}%</td>
                    <td className='td'>{tot_amount * (Number(props.info.cgst) / 100)}</td>
                    <td className='td'>{props.info.cgst}%</td>
                    <td className='td'>{tot_amount * (Number(props.info.sgst) / 100)}</td>
                    <td className='td'>{tot_amount * (Number(props.info.cgst) / 100) + tot_amount * (Number(props.info.sgst) / 100)}</td>
                </tr>
                <tr>
                    <td className='td' colSpan={3} id="right"><strong>Total</strong></td>
                    <td className='td'><strong>{tot_amount}</strong></td>
                    <td className='td'><strong>{props.info.cgst}%</strong></td>
                    <td className='td'><strong>{tot_amount * (Number(props.info.cgst) / 100)}</strong></td>
                    <td className='td'><strong>{props.info.cgst}%</strong></td>
                    <td className='td'><strong>{tot_amount * (Number(props.info.sgst) / 100)}</strong></td>
                    <td className='td'><strong>{tot_amount * (Number(props.info.cgst) / 100) + tot_amount * (Number(props.info.sgst) / 100)}</strong></td>
                </tr>
                <tr>
                    <td className='td' colSpan={2}><strong>Total Tax Amount (in words)</strong></td>
                    <td className='td' colSpan={7}><strong>{NumToText(tot_amount * (Number(props.info.cgst) / 100) + tot_amount * (Number(props.info.sgst) / 100),'',true)}</strong></td>
                    
                </tr>
                <tr>
                    <td className='td'  colSpan={2} rowSpan={2}>
                        Remarks &nbsp;  {props.info.remarks}<br /><br/>
                        Decleration<br />
                        We decleare that invoice shows the actual price of the goods
                    
                    </td>
                    <td className='td' id='right' colSpan={7} rowSpan={2}>
                        <strong>For Maa Ruhini Enterprise</strong><br /><br /><br />
                        Authorised signatory
                        </td>
                    
                </tr>
                

                
            </table>
        </div>
    )
}