import React from 'react';
import {Container, Paper, Toolbar, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button,LinearProgress} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SnakBar from '../others/massg';
import { Api } from '../../consts/api';

export default function History(){
    const navigate = useNavigate()
    const [data, setData] = React.useState([])
    const today = new Date()
    const next = today.setDate(today.getDate() + 7);
    const [date, setDate]= React.useState({
        to : new Date().toLocaleDateString("en-CA"),
        from: new Date(next).toLocaleDateString("en-CA"),
        refresh : Math.random()
    }) 
    const [loading,setLoading] = React.useState(false)
    const [fatching, setFatching] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const handleDate = (event)=>{
        let name = event.target.name;
        let value = event.target.value;

        setDate({
            ...date,
            [name] : value,
            
        })
    }

    React.useEffect(()=>{
       
            setFatching(true)
            fetch(Api+'invoice_history',{
                method : 'POST',
                body : JSON.stringify(date)
            })
            .then(res=>res.json())
            .then(res=>{
                setData(res)
                setFatching(false)
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    severity : 'error',
                    massg : 'Faild to connect to the server'
                })
               setFatching(false)
            })
        
    },[date])

  

   

    const handleNavigate = (item)=>{
        navigate('/Items',{state : item})
    }

    const onDelete = (id)=>{
        setFatching(true)
        fetch(Api+'deleteInvoice',{
            method : 'POST',
            body : JSON.stringify({
                id : id
            })
        })
        .then(res=>res.json())
        .then(res=>{
            

            if(res.code === 200){
                setMassg({
                    open : true,
                    severity : 'success',
                    massg : res.massg
                })
                setDate({
                    ...date,
                    refresh : Math.random()
                })
                setFatching(false)
            }else{
                setMassg({
                    open : true,
                    severity : 'error',
                    massg : res.massg
                })
            }
        })
        .catch(err=>{
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to the server'
            })
           setFatching(false)
        })
    }


    return(
        <Container>
            <Paper>
                <Toolbar style={{justifyContent : 'flex-end'}}>
                <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    name="to"
                    value={date.to}
                    onChange={handleDate}
                    
                />
                <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    name="from"
                    style={{marginLeft: 10,}}
                    value={date.from}
                    onChange={handleDate}
                    
                />
                </Toolbar>
            </Paper>
            <TableContainer component={Paper} style={{marginTop: 10}}>
                {
                    fatching ? <LinearProgress /> : ''
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>SL NO</TableCell>
                            <TableCell>INVOICE NO</TableCell>
                            <TableCell>BUYER NAME</TableCell>
                            <TableCell>DATE</TableCell>
                            <TableCell>REMARKS</TableCell>
                            <TableCell>MORE</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        data.map((item,index)=>
                            <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.buyer_name}</TableCell>
                                <TableCell>{item.date}</TableCell>
                                <TableCell>{item.remarks}</TableCell>
                                <TableCell>
                                        <IconButton style={{color : 'red'}} onClick={()=>onDelete(item.id)}>
                                            <Delete />
                                        </IconButton>
                                        <IconButton onClick={()=>handleNavigate(item)}>
                                            <ChevronRightIcon />
                                        </IconButton>
                            </TableCell>
                            </TableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>


            

            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}