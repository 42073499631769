import { ArrowBack, Download } from "@mui/icons-material"
import { Container, TableContainer,Paper,Table, TableHead, TableCell, TableBody, TableRow, Typography, Toolbar, IconButton } from "@mui/material"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PDFExport } from '@progress/kendo-react-pdf';
import Pdf from '../pdf';
import { Api } from "../../consts/api";


export default function ItemDetails(){
    const navigate = useNavigate()
    const props = useLocation()
    const pdfExportComponent = React.useRef(null);
    const [data, setData] = React.useState([])
   

    React.useEffect(()=>{
    fetch(Api+'invoiceItems',{
        method : 'POST',
        body : JSON.stringify({
            no : props.state.id
        })
    })
    .then(res=>res.json())
    .then(res=>setData(res))
    .catch(err=>{
       console.log(err)
    })
    },[])
    return(
        <Container>
        <Paper style={{marginBottom: 10}}>
            <Toolbar style={{justifyContent : 'space-between'}}>
                <div style={{display : 'flex',alignItems: 'center'}}>
                    <IconButton onClick={()=>navigate('/History')}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6">Invoice Item Details</Typography>
                </div>
                <IconButton
                    onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                >
                    <Download />
                </IconButton>

            </Toolbar>
        </Paper>
        <TableContainer component={Paper}>
            <Table>
            <TableHead>
                        <TableCell>SL No</TableCell>
                        <TableCell>Description of Goods</TableCell>
                        <TableCell>HSN/SAC</TableCell>
                        <TableCell>GST Rate</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>per</TableCell>
                        <TableCell>Disc %</TableCell>
                        <TableCell>Amount</TableCell>
            </TableHead>
            <TableBody>
            {
                data.map((item,index)=>{
                    let amount = Number(item.rate) * Number(item.qty) 
                            if(item.disc > 0){
                                let disc = amount * Number(item.disc)/100
                                amount = amount - disc
                            }
                    return(
                        <TableRow key={index}>
                            <TableCell>{index+1}</TableCell>
                            <TableCell>
                                <Typography style={{fontWeight : 'bold'}}>{item.name}</Typography>
                                <Typography >{item.description}</Typography>
                            </TableCell>
                            <TableCell>{item.hsn}</TableCell>
                            <TableCell>{item.gst_rate}</TableCell>
                            <TableCell>{item.qty}</TableCell>
                            <TableCell>{item.rate}</TableCell>
                            <TableCell>{item.per}</TableCell>
                            <TableCell>{item.disc}</TableCell>
                            <TableCell>{amount}</TableCell>
                            
                        </TableRow>
                    )
                })
            }
            </TableBody>
            </Table>
        </TableContainer>
        <div
                style={{
                    position: "absolute",
                    left: "-1000px",
                    top: 0,
                  }}
            > 
            <PDFExport  keepTogether='.keep' paperSize="A4"  fileName='invoice.pdf' margin={10} ref={pdfExportComponent}>
                <Pdf info={props.state} item={data} invoice_no={props.state.id} />
            </PDFExport>
            </div>
        </Container>
    )
}