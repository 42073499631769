import { Container, Dialog, DialogContent, DialogTitle,TextField,Button, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { Api } from "../../consts/api";
import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import SnakBar from "../others/massg";



export default function ProductManager({refresh, setRefresh}){
    const [data, setData] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [dialogView, setDialogView] = React.useState(false)
    const [selected, setSelected] = React.useState({})
    const [err, setErr] = React.useState({})
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'products')
        .then(res=>res.json())
        .then(res=>{
            setData(res)
            setLoading(false)
        })
        .catch(err=>{
            setLoading(false)
           console.log(err)
        })
    },[refresh])

    const onDelete = (id)=>{
        setLoading(true)
        fetch(Api+'onDelete',{
            method : 'POST',
            body : JSON.stringify({
                id : id
            })
        })
        .then(res=>res.json())
        .then(res=>{
            console.log(res)
            setRefresh(Math.random())
            setLoading(false)
            setMassg({
                open : true,
                severity : res.code === 200 ? 'success' : 'error',
                massg : res.massg
            })
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                open : true,
                severity :  'error',
                massg : 'Faild to connect to the server'
            })
        })
    }

    const onEditProduct = (item)=>{
        setDialogView(true)
        setSelected(item)
    }

    const handleDialogClose = ()=>{
        setDialogView(false)
        setSelected({})
    }
    const handleChange = (e)=>{
        setSelected({
            ...selected,
            [e.target.name] : e.target.value
        })
    }

    const onSubmit = ()=>{
        fetch(Api+'update_product',{
            method : 'POST',
            body : JSON.stringify(selected)
        })
        .then(res=>res.json())
        .then(res=>{
            setRefresh(Math.random())
            if(res.code === 200){
                setMassg({
                   open : true,
                   severity : 'success',
                   massg : res.massg 
                });
                handleDialogClose();
            }else {
                setMassg({
                    open : true,
                    severity : 'error',
                    massg : res.massg 
                 })
            }
        })
        .catch(err=>{
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to the server'
             })
        })  
    }

    const onSearch = (e)=>{
        setData([])
        setLoading(true)
         fetch(Api+'searchProduct',{
           method : 'POST',
           body : JSON.stringify({
             search : e.target.value
           })
         })
         .then(res=>res.json())
         .then(res=>{ 
           setData(res)
           setLoading(false)
         })
         .catch(err=>{
           setLoading(false)
            console.log(err)
         })
    }

    return (
        <Container >
            <Paper variant="outlined" style={{padding: 5}}>
                <TextField
                    label="Search product with name"
                    variant="outlined"
                    onChange={onSearch}
                    size="small"
                    

                />
            </Paper>
            <TableContainer component={Paper} variant="outlined" >
                {loading ? <LinearProgress /> : ''}
                <Table>
                    <TableHead>
                        <TableCell>
                            Product Name
                        </TableCell>
                        <TableCell>
                        Price     
                        </TableCell>
                        <TableCell>stock</TableCell>
                        <TableCell>hsn</TableCell>
                        <TableCell>unit</TableCell>
                        <TableCell>more</TableCell>
                    </TableHead>
                    <TableBody>
                    {
                        data.map((item,index)=>
                            <TableRow key={index}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.stock}</TableCell>
                                <TableCell>{item.hsn}</TableCell>
                                <TableCell>{item.unit}</TableCell>
                                <TableCell>
                                    <IconButton onClick={()=>onEditProduct(item)}>
                                        <Tooltip title={'Edit product Details'}>
                                            <Edit />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton onClick={()=>onDelete(item.id)}>
                                    <Tooltip title={'Delete this product'}>
                                        <Delete />
                                    </Tooltip>
                                    </IconButton>
                                </TableCell>
                               
                            </TableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={dialogView}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    Edit Product
                </DialogTitle>
                <DialogContent>
                <Paper variant="outlined" style={{padding: 10}}>
                        <h3>Add Product</h3>
                        <hr />
                        {loading ? <LinearProgress /> : ''}
                        <TextField
                            variant="outlined"
                            name="name"
                            label="Product Name"
                            fullWidth
                            value={selected.name}
                            error={err.name}
                            onChange = {handleChange}
                            required
                            style={{marginTop: 10}}
                            size="small"
                        />
                        <TextField
                            variant="outlined"
                            name="price"
                            label="price"
                            fullWidth
                            value={selected.price}
                            error={err.price}
                            onChange = {handleChange}
                            required
                            number
                            style={{marginTop: 10}}
                            size="small"
                            type='number'
                        />
                        <TextField
                            variant="outlined"
                            name="stock"
                            label="Stock"
                            fullWidth
                            value={selected.stock}
                            error={err.stock}
                            onChange = {handleChange}
                            required
                            style={{marginTop: 10}}
                            size="small"
                            type='number'
                        />
                        <TextField
                            variant="outlined"
                            name="hsn"
                            label="HSN"
                            fullWidth
                            value={selected.hsn}
                            error={err.hsn}
                            onChange = {handleChange}
                            
                            style={{marginTop: 10}}
                            size="small"
                        />
                        <TextField
                            variant="outlined"
                            name="unit"
                            label="Unit"
                            fullWidth
                            value={selected.unit}
                            error={err.unit}
                            onChange = {handleChange}
                            
                            style={{marginTop: 10}}
                            size="small"
                            helperText="Ex: pcs, kg, Dozen,  "
                        />
                        <Button variant="contained" onClick={onSubmit} disabled={loading} style={{marginTop : 15}} fullWidth color="primary">
                            Save Product
                        </Button>

                        
                       
                    </Paper>
                </DialogContent>

            </Dialog>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}