import { Button, Container } from "@mui/material";
import { Api } from "../../consts/api";
import SnakBar from "./massg";
import React from "react";

export default function Format(){
const [massg,setMassg] = React.useState({})

    const onFormat = ()=>{
        fetch(Api+'format')
        .then(res=>res.json())
        .then(res=>{
            if(res.code === 200){
                setMassg({
                    open : true,
                    severity : 'success',
                    massg : res.massg
                })
            }else{
                setMassg({
                    open : true,
                    severity : 'error',
                    massg : res.massg
                })
            }
        })
        .catch(err=>{
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to the server'
            })
        })
    }

    return (
        <Container>
            <h2>Are you sure to format</h2>
            <Button variant="contained" onClick={onFormat} color="primary">
                Format
            </Button>
            <SnakBar massg={massg} setMassg={setMassg} /> 
        </Container>
    )
}