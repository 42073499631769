
const color = {
    primary : '#a1003e',
    secondary : '#fcdcec',
    white : '#fff',
    black : '#000',
    background: 'aliceblue',
    secondary_light : '#ffedf6'
}

export default color