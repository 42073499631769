import { Grid,Container,Paper, TextField, LinearProgress, Button, TableContainer, TableHead, TableCell, TableBody,Table, TableRow, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Api } from "../../consts/api";
import SnakBar from "../others/massg";
import {GiCardExchange} from 'react-icons/gi'

export default function BlanceManager(){
    const [formData, setFormData] = React.useState({})
    const [err,setErr] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [data, setData] = React.useState([])
    const [anchorEl, setAnchorE1] = React.useState(null)
    const open = Boolean(anchorEl)
    const [selectedId, setSelectedId] = React.useState(null)
    const [refresh, setRefresh] = React.useState(Math.random())
    const [productLoading, setProductLoading] = React.useState(false)
    const handleChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const validate  = ()=>{
        let valid = true
        let err = {}

        if(!formData.name){
            valid = false
            err['name'] = true
        }
        if(!formData.c_no){
            valid = false
            err['c_no'] = true
        }
        if(!formData.amount){
            valid = false
            err['amount'] = true
        }
        if(!formData.date){
            valid = false
            err['date'] = true
        }

        setErr(err)
        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
            setProductLoading(true)
        fetch(Api+'bal_update',{
          method : 'POST',
          body : JSON.stringify(formData)
        })
        .then(res=>res.json())
        .then(res=>{
            setProductLoading(false)
          if(res.code === 200){
            setRefresh(Math.random())
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
            setProductLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
        }
    }

    React.useEffect(()=>{
        setProductLoading(true)
        fetch(Api+'balance')
        .then(res=>res.json())
        .then(res=>{
            setData(res)
            setProductLoading(false)
        })
        .catch(err=>{
           console.log(err)
        })
    },[refresh])
    
    const handleMenuClose = ()=>{
        setAnchorE1(null)
    }

    const onSelectChange = (e,id)=>{
        setAnchorE1(e)
        setSelectedId(id)
    }

    const updateStatus = (status)=>{
        setProductLoading(true)
        setAnchorE1(null)
        fetch(Api+'updateStatus',{
          method : 'POST',
          body : JSON.stringify({id : selectedId,status : status})
        })
        .then(res=>res.json())
        .then(res=>{
            setProductLoading(false)
          setRefresh(Math.random())
          if(res.code === 200){
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
            setProductLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
    }

    const handleSearch = (e)=>{
        setProductLoading(true)
        fetch(Api+'searchCustomer',{
            method : 'POST',
            body : JSON.stringify({
                query : e.target.value
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setData(res)
            setProductLoading(false)
        })
        .catch(err=>{
           console.log(err)
           setProductLoading(false)
        })
    }
    return(
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>

                    <Paper variant="outlined">
                        {loading ? <LinearProgress /> : ''}
                        <div style={{padding: 15}}>
                        <h3>Add Balance</h3>
                        <TextField
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={formData.name}
                            error={err.name}
                            onChange={handleChange}
                            required
                            fullWidth
                            style={{margin: 5}}
                        />
                        <TextField
                            label="Contact No"
                            variant="outlined"
                            name="c_no"
                            value={formData.c_no}
                            error={err.c_no}
                            onChange={handleChange}
                            required
                            fullWidth
                            style={{margin: 5}}
                        />
                        <TextField
                            helperText="Date"
                            variant="outlined"
                            name="date"
                            value={formData.date}
                            error={err.date}
                            onChange={handleChange}
                            required
                            fullWidth
                            style={{margin: 5}}
                            type="date"
                        />
                        <TextField
                            label="Address"
                            variant="outlined"
                            name="address"
                            value={formData.address}
                            error={err.address}
                            onChange={handleChange}
                            fullWidth
                            style={{margin: 5}}
                            
                        />
                        <TextField
                            label="Amount"
                            variant="outlined"
                            name="amount"
                            value={formData.amount}
                            error={err.amount}
                            onChange={handleChange}
                            required
                            fullWidth
                            style={{margin: 5}}
                        />

                        <Button style={{margin: 5}} fullWidth variant="contained" onClick={onSubmit} color="primary">
                            Save
                        </Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper variant="outlined" style={{padding: 10, marginBottom: 10}}>
                        <TextField 
                            label="Search with customer name"
                            variant="outlined"
                            onChange={handleSearch}
                            fullWidth
                        />
                    </Paper>
                    <Paper variant="outlined">
                        {productLoading ? <LinearProgress /> : ''}
                      <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableCell>Name</TableCell>
                                <TableCell>Contact No</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>date</TableCell>
                                <TableCell>amount</TableCell>
                                <TableCell>status</TableCell>
                                <TableCell>Change status</TableCell>
                            </TableHead>
                            {
                                <TableBody>
                                {
                                    data.map((item,index)=>
                                        <TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.c_no}</TableCell>
                                            <TableCell>{item.address}</TableCell>
                                            <TableCell>{item.date}</TableCell>
                                            <TableCell>{item.amount}</TableCell>
                                            <TableCell>{item.status}</TableCell>
                                            <TableCell>
                                            <IconButton onClick={(e)=>onSelectChange(e.currentTarget,item.id)}>
                                                <GiCardExchange />
                                            </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                </TableBody>
                            }
                        </Table>
                      </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
            <Menu
             anchorEl={anchorEl}
             open={open}
             onClose={handleMenuClose}
            >
                <MenuItem onClick={()=>updateStatus('paid')}>paid</MenuItem>
                <MenuItem  onClick={()=>updateStatus('not paid')}>not paid</MenuItem>
            </Menu>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}