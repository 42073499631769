import { Button, Container, TextField, Paper, LinearProgress } from "@mui/material";
import React from "react";
import { Api } from "../../consts/api";
import SnakBar from "./massg";




export default function ChangePass(){
    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const handleChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    } 

    const validate = ()=>{
        let valid = true
        let err = {}

        if(!formData.old){
            valid = false
            err['old'] = true
        }
        if(!formData.new){
            valid = false
            err['new'] = true
        }

        setErr(err)
        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
      setLoading(true)
      fetch(Api+'auth',{
        method : 'POST',
        body : JSON.stringify({
            username : 'admin',
            password : formData.old
        })
      })
      .then(res=>res.json())
      .then(res=>{
        setLoading(false);
        if(res.code === 200){
            update_pass()
          sessionStorage.setItem('authState',true)
          setLoading(false)
        }else{
          setMassg({
            open : true,
            severity : 'error',
            massg : res.massg
          })
        }
      })
      .catch(err=>{
         console.log(err)
         setLoading(false)
         setMassg({
          open : true,
          severity : 'error',
          massg : 'Faild to connect to server'
        })
        })
    }
}



const update_pass = ()=>{
    setLoading(true)
    fetch(Api+'update_pass',{
        method : 'POST',
        body : JSON.stringify({
            password : formData.new
        })
    })
    .then(res=>res.json())
    .then(res=>{
        setLoading(false);
        if(res.code === 200){
            setMassg({
                open : true,
                severity : 'success',
                massg : res.massg
              })
         
          setLoading(false)
        }else{
          setMassg({
            open : true,
            severity : 'error',
            massg : res.massg
          })
        }
    })
    .catch(err=>{
        setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to server'
          })
    })

   
}

    return(
        <Container maxWidth="sm" component={Paper} style={{padding: 5}}>
            {loading ? <LinearProgress /> : ''}
            <TextField
                label="Enter old password"
                variant="outlined"
                value={formData.old}
                name="old"
                error={err.old}
                fullWidth
                required
                onChange={handleChange}
                style={{margin: 10}}
            />
            <TextField
                label="Enter new password"
                variant="outlined"
                value={formData.new}
                name="new"
                error={err.new}
                fullWidth
                required
                onChange={handleChange}
                style={{margin: 10}}
            />
            <Button variant="contained" onClick={onSubmit}>
                Submit
            </Button>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}