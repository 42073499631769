import { AddCircle, CurrencyRupee, Delete } from '@mui/icons-material'
import { Container, Grid, TextField, Paper, TableContainer,InputBase,Autocomplete, Table, TableHead, TableCell, TableRow, TableBody, Tab, Typography, Button, IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Toolbar, LinearProgress, Menu, MenuItem} from '@mui/material'
import React from 'react'
import { PDFExport } from '@progress/kendo-react-pdf';
import Pdf from './pdf';
import SnakBar from './others/massg';
import { Api } from '../consts/api';
import { styled, alpha, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      
      transition: theme.transitions.create('width'),
      width: '100%',
      
      [theme.breakpoints.up('sm')]: {
        width: '1ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));
export default function Maker(){

    const pdfExportComponent = React.useRef(null);
    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [view, setView] =React.useState(false)
    const [pass, setPass] = React.useState('')
    const [preview, setPreview] = React.useState(false)
    const [loading,setLoading] = React.useState(false)
    const [invoiceId, setInvoiceId] = React.useState(null)
    const [massg,setMassg] = React.useState({})

    const [productMenuItem, setProductMenuItem] = React.useState([])
    const [data, setData] = React.useState([
        {
            name : '',
            description : '',
            hsn : '',
            gst_rate : '',
            qty : 1,
            rate : '',
            per: 1,
            disc : ''
        }
    ])


    const onAddItem = ()=>{
       let modify = [
           {
            name : '',
            description : '',
            hsn : '',
            gst_rate : '',
            qty : 1,
            rate : '',
            per: 1,
            disc : ''
           }
       ]

       setData(data.concat(modify))

       
    }


    const handleDelete = (i)=>{
        const filteredItems = data.slice(0, i).concat(data.slice(i+1, data.length))
        setData(filteredItems)
    }

    const handleChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })

        
    }
    
    const handleOrderChange = (value,id,name)=>{
        const modify  = [...data]
        
        modify[id][name] = value
    
        setData(modify)

        if(name === "name"){
            searchItem(value)
        }

    }

    const validate = ()=>{

        let valid = true
        let error = {}

        if(!formData.buyer_name){
            valid = false
            error['buyer_name'] = true
        }
        if(!formData.buyer_description){
            valid = false
            error['buyer_description'] = true
        }
        if(!formData.cgst){
            valid = false
            error['cgst'] = true
        }
        if(!formData.sgst){
            valid = false
            error['sgst'] = true
        }
        if(!formData.date){
            valid = false
            error['date'] = true
        }
        if(invoiceId === null){
            setMassg({
                open : true,
                severity : 'error',
                massg: 'Invoice NO not generated, Please refresh the window'
            })
        }

        setErr(error)
        return valid
    }

    const modalView = ()=>{
        if(validate()){
            setView(true)
        }
    }

    const saveToserver = ()=>{
        fetch(Api+'save_invoice',{
            method : 'POST',
            body : JSON.stringify({
                invoice_details : formData,
                item_details : data
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setLoading(false)
            if(res.code === 200){
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
              }
            setView(false)
            setPreview(false)
            }
            setMassg({
                open : true,
                severity : 'success',
                massg : 'Downloading'
            })

            console.log(res)
        })
        .catch((err)=>{
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to the server'
            })
        })
    }

    const download = ()=>{
      setLoading(true) 
      saveToserver()
    }

    React.useState(()=>{
        fetch(Api+'invoiceId')
        .then(res=>res.json())
        .then(res=>setInvoiceId(Number(res.scalar) + 1))
        .catch(err=>{
            
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to the server'
            })
        })
    },[])

    const handlePreview = ()=>{
        if(validate()){
            setPreview(true)
        }
    }
    const options = productMenuItem.map(function(row){

        return {label : row.name, id : row.id,stock : row.stock, rate : row.price,name : row.name,hsn : row.hsn, pcs : row.unit}
      })


    const searchItem = (query)=>{
        
        setProductMenuItem([])
        setLoading(true)
         fetch(Api+'searchProduct',{
           method : 'POST',
           body : JSON.stringify({
             search : query
           })
         })
         .then(res=>res.json())
         .then(res=>{ 
           setProductMenuItem(res)
           setLoading(false)
         })
         .catch(err=>{
           setLoading(false)
            console.log(err)
         })
    }

    const onProductSave = (e,v,index)=>{
        if(v.stock < 1){
            setMassg({
                open : true,
                severity : 'error',
                massg : "out of stock"
            })
        }else{
            const modify  = [...data]
            Object.keys(v).map((item,indexes)=>
            {
                console.log(item)
                modify[index][item] = v[item]
            }
            )
            setData(modify)
        }
    }
   

    return(
        <Container style={{backgroundColor : '#fff',padding : 25,borderRadius: 10}}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <Paper style={{padding: 30,paddingTop: 2}} variant="outlined">
                        <h3>Buyer Info</h3>
                        <TextField
                            label="Buyer Name"
                            variant='outlined'
                            fullWidth
                            value={formData.buyer_name}
                            onChange={handleChange}
                            error={err.buyer_name}
                            name="buyer_name"

                        />
                        <TextField
                            label="Buyer Description"
                            variant='outlined'
                            style={{marginTop: 20}}
                            fullWidth
                            value={formData.buyer_description}
                            onChange={handleChange}
                            error={err.buyer_description}
                            name="buyer_description"
                            multiline
                        />
                    </Paper>
                    <Paper style={{marginTop: 10, padding: 30,paddingTop: 2}} variant="outlined">
                        <h3>GST Info</h3>
                        <TextField
                            label="cgst(%)"
                            variant='outlined'
                            fullWidth
                            type={'number'}
                            value={formData.cgst}
                            onChange={handleChange}
                            error={err.cgst}
                            name="cgst"
                        />
                        <TextField
                            label="sgst(%)"
                            variant='outlined'
                            fullWidth
                            style={{marginTop: 10}}
                            type={'number'}
                            value={formData.sgst}
                            onChange={handleChange}
                            error={err.sgst}
                            name="sgst"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Paper style={{padding: 15,paddingTop: 2}} variant="outlined">
                        <h3>Other Info</h3>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                helperText="Dated"
                                variant="outlined"
                                style={{margin: 5}}
                                type="date"
                                fullWidth
                                value={formData.date}
                                onChange={handleChange}
                                error={err.date}
                                name="date"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Delivery Note"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.del_note}
                                onChange={handleChange}
                                error={err.del_note}
                                name="del_note"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Mode/Terms of payment"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.mode}
                                onChange={handleChange}
                                error={err.mode}
                                name="mode"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Supplier's Ref"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.supplier_ref}
                                onChange={handleChange}
                                error={err.supplier_ref}
                                name="supplier_ref"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Other Rrference(s)"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.other_ref}
                                onChange={handleChange}
                                error={err.other_ref}
                                name="other_ref"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Buyer's order no"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.buyer_order_no}
                                onChange={handleChange}
                                error={err.buyer_order_no}
                                name="buyer_order_no"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                helperText="Dated"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                type="date"
                                value={formData.d_date}
                                onChange={handleChange}
                                error={err.d_date}
                                name="d_date"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Despatch document No"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.dis_document_no}
                                onChange={handleChange}
                                error={err.dis_document_no}
                                name="dis_document_no"
                            />
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Despatch Note Date"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.dis_note_date}
                                onChange={handleChange}
                                error={err.dis_note_date}
                                name="dis_note_date"
                                />
                            </Grid>
                           
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Despatched through"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.dis_through}
                                onChange={handleChange}
                                error={err.dis_through}
                                name="dis_through"
                            />
                            </Grid>
                           
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Destination"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.destination}
                                onChange={handleChange}
                                error={err.destination}
                                name="destination"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Terms of Delivery"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.t_o_d}
                                onChange={handleChange}
                                error={err.t_o_d}
                                name="t_o_d"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                label="Remarks"
                                variant='outlined'
                                style={{margin: 5}}
                                fullWidth
                                value={formData.remarks}
                                onChange={handleChange}
                                error={err.remarks}
                                name="remarks"
                            />
                            </Grid>
                        </Grid>
                        
                        
                        
                        
                        
                    </Paper>
                </Grid>
            </Grid>
            <Paper variant="outlined" style={{marginTop: 10}}>
            <TableContainer >
                <Table>
                    <TableHead>
                        <TableCell>SL No</TableCell>
                        <TableCell>Description of Goods</TableCell>
                        <TableCell>HSN/SAC</TableCell>
                        <TableCell>GST Rate</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>per</TableCell>
                        <TableCell>Disc %</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody>
                    {
                        data.map((item,index)=>{
                            let amount = Number(item.rate) * Number(item.qty) 
                            if(item.disc > 0){
                                let disc = amount * Number(item.disc)/100
                                amount = amount - disc
                            }

                            return(
                                <TableRow key={index}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>
                                        {/* <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.name}
                                            label="Item Name"
                                            name="name"
                                            onChange={(e)=>handleOrderChange(e.target.value,index,'name',e.currentTarget)}
                                            
                                        /> */}
                                        <Search style={{border : '1px solid #000'}}>
                                            
                                            
                                            <Autocomplete
                                            disablePortal
                                            options={options}
                                            name = "name"
                                            onChange={(e,v)=>onProductSave(e,v,index)}
                                            renderInput={
                                                (params)=>{
                                                const {InputLabelProps,InputProps,...rest} = params;
                                                return  <StyledInputBase
                                                            {...params.InputProps} {...rest}
                                                            
                                                            onChange={(e)=>handleOrderChange(e.target.value,index,'name',e.currentTarget)}
                                                        /> 
                                                }}
                                            />
                                        </Search> 
                                        <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.description}
                                            label="description"
                                            name="description"
                                            onChange={({target : {value}})=>handleOrderChange(value,index,'description')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.hsn}
                                            label=""
                                            onChange={({target : {value}})=>handleOrderChange(value,index,'hsn')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.gst_rate}
                                            label=""
                                            onChange={({target : {value}})=>handleOrderChange(value,index,'gst_rate')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.qty}
                                            label=""
                                            onChange={({target : {value}})=>handleOrderChange(value,index,'qty')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.rate}
                                            label=""
                                            InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <CurrencyRupee style={{fontSize : 16}}/>
                                                  </InputAdornment>
                                                ),
                                              }}
                                              onChange={({target : {value}})=>handleOrderChange(value,index,'rate')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                    <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.pcs}
                                            label=""
                                            onChange={({target : {value}})=>handleOrderChange(value,index,'pcs')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant='standard'
                                            fullWidth
                                            value={item.disc}
                                            label=""
                                            onChange={({target : {value}})=>handleOrderChange(value,index,'disc')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{display : 'flex'}}><CurrencyRupee style={{fontSize : 16}}/>{amount}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={()=>handleDelete(index)} style={{color : 'red'}}>
                                            <Delete  />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
                
            </TableContainer>
                <Button color="primary" onClick={()=>onAddItem()} style={{margin: 10}}>
                   
                    <AddCircle size={12} />
                    New Item
                </Button>
            </Paper>

            <Toolbar>
            <Button 
                variant='contained' 
                color='primary'
                style={{
                    marginRight : 10
                }}

                onClick={handlePreview}
               
            >
                Preview
            </Button>
            <Button 
                variant='contained' 
                color='success'
                // onClick={() => {
                //     if (pdfExportComponent.current) {
                //       pdfExportComponent.current.save();
                //     }
                //   }}

                onClick={modalView}
            >
                Create Invoice
            </Button>
            </Toolbar>

            <div
                style={{
                    position: "absolute",
                    left: "-1000px",
                    top: 0,
                  }}
            > 
            <PDFExport  keepTogether='.keep' paperSize="A4"  fileName='invoice.pdf' margin={10} ref={pdfExportComponent}>
                <Pdf info={formData} item={data} invoice_no={invoiceId} />
            </PDFExport>
            </div>

            <Dialog
                open={view}
                onClose={()=>setView(false)}
            >{
                loading ? <LinearProgress /> : ''
            }
                <DialogTitle >Are you sure?</DialogTitle>
                <DialogContent>
                    
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="success" onClick={download}>
                        Download and Save
                    </Button>
                </DialogActions>

            </Dialog>

            <Dialog
                open={preview}
                onClose={()=>setPreview(false)}
            >
                <DialogTitle>Invoice Preview</DialogTitle>
                <DialogContent>
                    <Pdf info={formData} item={data} invoice_no={invoiceId} />
                </DialogContent>
                <DialogActions>
                <Toolbar>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={()=>setPreview(false)}
                        style={{marginRight: 10}}
                    >
                        Close
                    </Button>
                    <Button 
                        variant='contained' 
                        color='success'
                        // onClick={() => {
                        //     if (pdfExportComponent.current) {
                        //       pdfExportComponent.current.save();
                        //     }
                        //   }}

                        onClick={modalView}
                    >
                        Create Invoice
                    </Button>
                </Toolbar>
                </DialogActions>
            </Dialog>
            <SnakBar massg={massg} setMassg={setMassg} />

            
        </Container>
    )
}