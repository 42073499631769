import './App.css';


import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar, Button, Dialog, DialogContent, DialogTitle, Divider, InputBase, LinearProgress, Paper, TextField, useMediaQuery } from '@mui/material';

import color from './consts/color';
import NavList from './componennts/others/nav_list';
import { Api } from './consts/api';
import SnakBar from './componennts/others/massg';
import Products from './componennts/products/product';

import Topbar from './componennts/topbar';
import Maker from './componennts/maker';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import History from './componennts/history/history';
import ItemDetails from './componennts/history/item_det';
import ProductManager from './componennts/products/manageProducts';
import BlanceManager from './componennts/balance/balance';
import ChangePass from './componennts/others/change_pass'
import Format from './componennts/others/format'
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open,isMobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isMobile ? `-${drawerWidth}px` : 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',

}));



export default function Home() {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(matchUpMd ? true : false);
 
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const authState = sessionStorage.getItem('authState');
  const [dialogView,setDialogView] = React.useState(false)
  const [formData, setFormData] = React.useState({})
  const [err, setErr] = React.useState({})
  const [loading,setLoading] = React.useState(false)
  const [massg,setMassg] = React.useState({})
  React.useEffect(()=>{
    if(!authState){
      setDialogView(true)
    }else{
      setDialogView(false)
    }
  },[])

  const handleChange = (e)=>{
    setFormData({
      ...formData,
      [e.target.name] : e.target.value
    })
  }
  const onAuth = ()=>{
    if(validate()){
      setLoading(true)
      fetch(Api+'auth',{
        method : 'POST',
        body : JSON.stringify(formData)
      })
      .then(res=>res.json())
      .then(res=>{
        setLoading(false);
        if(res.code === 200){
          setDialogView(false)
          sessionStorage.setItem('authState',true)
          setLoading(false)
        }else{
          setMassg({
            open : true,
            severity : 'error',
            massg : res.massg
          })
        }
      })
      .catch(err=>{
         console.log(err)
         setLoading(false)
         setMassg({
          open : true,
          severity : 'error',
          massg : 'Faild to connect to server'
        })
      })
    }
  }

  const validate = ()=>{
    let valid = true
    let err = {}

    if(!formData.username){
      valid = false
      err['username'] = true
    }

    if(!formData.password){
      valid = false
      err['password'] = true
    }

    setErr(err)
    return valid
  }

 

  return (
    <BrowserRouter>
    <Box>
      <AppBar
        position="fixed" 
        open={open}
        style={{
            backgroundColor: color.white,
            color: color.black,
            display : 'flex',
            flexDirection : 'row',
            justifyContent : 'space-between'
        }}
        elevation={0}
      >
        <Toolbar>
          
          {
            matchUpMd ? <Typography style={{color: color.black, ...(open && {display: 'none'})}} variant="h6" noWrap component="div">
           Maa Ruhini
          </Typography> : ''
          }
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 0,
                 ...(open && { display: 'none' }),
                 backgroundColor: color.secondary,
                 color: color.primary,
                 marginLeft: 5
                 }}
          >
            <MenuIcon />
          </IconButton> */}
          <IconButton 
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{ mr: 0,
                backgroundColor: color.secondary,
                color: color.primary,
                marginLeft: matchUpMd ? 5 : 0
                }}
        >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          {/* <Paper style={{ padding: 5,display: 'flex',alignItems: 'center',marginLeft: 10,margin: 5}} variant="outlined">
                <IconButton  aria-label="search">
                         <BsSearch />
                    </IconButton>
                <InputBase
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    placeholder="Search by account no or name"
                    inputProps={{ 'aria-label': 'Search by account no or name' }}
                    // className={classess.input}
                    // onChange={handleChange}
                    style={{flex: 1}}
                    fullWidth
                    />
                    
            </Paper> */}
        </Toolbar>
        
      </AppBar>
      <Box sx={{ display: 'flex' }} >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
        PaperProps={{style: {border: 'none'}}}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        onClose={handleDrawerClose}
        
        
      >
        <DrawerHeader style={{height: '4vh',margin:0}}>
          <Typography style={{color: color.black,marginRight: 10,fontWeight: 600,marginLeft: 15}} variant="h6" noWrap component="div">
            Maa Ruhini
          </Typography>
          
        </DrawerHeader>
        <div className='App-test'>
        <NavList />
        </div>
        
        
      </Drawer>
      <Main 
        open={open}
        style={{
            backgroundColor : color.white,
            maxHeight: '94vh',
            marginTop: 50,
            maxWidth : '100%',
            padding: matchUpMd ? 20 : 0
            
            
        }}
        isMobile={matchUpMd}
      >
        
        <Box
            style={{
                backgroundColor : color.background,
                height: '90vh',
                borderRadius: 15,
                
                overflow: 'auto',
               
            }}
        >
         
            <div style={{padding: 20}}>
            <Routes>
            <Route path='/' element={<Maker />} />
            <Route path='/History' element={<History />} />
            <Route path='/Items' element={<ItemDetails />} />
            <Route path='/Products' element={<Products />} />
            <Route path='/AllProducts' element={<ProductManager />} />
            <Route path='/BlanceManager' element={<BlanceManager />} />
            <Route path='/ChangePass' element={<ChangePass />} />
            <Route path='/Format' element={<Format />} />
            </Routes>
            </div>
        </Box>
      </Main>
      </Box>
    </Box>
     <Dialog
        open={dialogView}
        keepMounted
        
      >
        <DialogTitle>
          Login to Continue
        </DialogTitle>
        <Divider />
        {loading ? <LinearProgress /> : ''}
        <DialogContent>
          <Box sx={{display : 'flex', flexDirection: 'column'}}>
          <TextField
            variant='outlined'
            label="Enter Username"
            fullWidth
            name="username"
            value={formData.username}
            error={err.username}
            onChange={handleChange}
            required
            style={{margin: 5}}

          />
          <TextField
            variant='outlined'
            label="Enter Password"
            fullWidth
            name="password"
            value={formData.password}
            error={err.password}
            onChange={handleChange}
            required
            style={{margin: 5}}
            type="password"
          />
          <Button variant='contained' color='primary' style={{margin: 5}} onClick={onAuth}>
            Login
          </Button>
          </Box>
        </DialogContent>
      </Dialog>
     
      <SnakBar massg={massg} setMassg={setMassg} />
    </BrowserRouter>
  );
}
















