import {AiOutlineDashboard,AiOutlineLogout} from 'react-icons/ai'
import { Divider, List, ListItemButton, ListSubheader, Toolbar, Typography } from '@mui/material'
import color from '../../consts/color'
import React from 'react'
import {GiArchiveRegister, GiProfit} from 'react-icons/gi'
import {TbCashBanknote, TbInfoSquare} from 'react-icons/tb'
import {useNavigate} from 'react-router-dom'
import { BiHistory } from 'react-icons/bi'
import { AddModerator, Collections, EmojiPeople } from '@mui/icons-material'
import {IoCreateSharp} from 'react-icons/io5'
const list = [
    {
       name : 'Invoice Manager',
       sub_list : [
        {
            name: 'Create Invoice',
            link : '/',
            icon : <IoCreateSharp size={24} />,
        },
        {
            name: 'Invoice History',
            link : '/History',
            icon : <BiHistory size={24} />,
        },

       ]
    },
    {
       name : 'Inventory Manager',
       sub_list : [
        {
            name: 'Add Product',
            link : '/Products',
            icon : <GiArchiveRegister size={24} />,
        },
        // {
        //     name: 'All Products',
        //     link : '/AllProducts',
        //     icon : <TbInfoSquare size={24} />,
        // },
        {
            name: 'Balance Manager',
            link : '/BlanceManager',
            icon : <TbInfoSquare size={24} />,
        },
        
       ]
    },
    {
        name : 'Reports',
        sub_list : [
         {
             name: 'Cash Book',
             link : '/Cashbook',
             icon : <TbCashBanknote size={24} />,
             disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
         },
         {
             name: 'Profit & Loss',
             link : '/StudentRegister',
             icon : <GiProfit size={24} />,
             disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
         }
        ]
     },
     {
        name : 'Settings',
        sub_list : [
         {
             name: 'Change Password',
             link : '/ChangePass',
             icon : <AddModerator size={24} />,
             
         },
         {
             name: 'Format',
             link : '/Format',
             icon : <EmojiPeople size={24} />,
            
         },
        ]
     },
    {
        name : 'Authentication',
        sub_list : [
         {
             name: 'Sign Out',
             link : '/',
             icon : <AiOutlineLogout size={24} />,
         }
        ]
     },
]

export default function NavList(){
    const navigate = useNavigate()
    const [selected, setSelected] = React.useState({main : 0, sub : 0})

    const handleRoute = (item,main,sub)=>{
        navigate(item.link)
        setSelected({
            main : main,
            sub: sub
        })

        if(item.name === 'Sign Out'){
            logOut();
        }
    }

    const logOut =()=>{
        sessionStorage.removeItem('authState')

        setInterval(()=>{
            window.location.reload('/')
        },2000)
    }

    return(
        <>
        {
            list.map((item,index)=>
                <List
                    key={index}
                    component="nav"
                    subheader={
                        <ListSubheader component={'div'} style={{fontWeight: 600,padding: 0,margin: 0}} id="nested-list-subheader">
                            {item.name}
                        </ListSubheader>
                    }
                    style={{
                        padding: 10,
                        paddingLeft: 20
                    }}
                >
                {
                    item.sub_list != null ? (
                    <List>
                    {
                        item.sub_list.map((sub_item,sub_index)=>
                            <ListItemButton onClick={()=>handleRoute(sub_item,index,sub_index)} disabled={sub_item.disabled} key={sub_index} style={{marginLeft: 15,borderRadius: 10, backgroundColor: index === selected.main && sub_index === selected.sub ? color.secondary : '',color: index === selected.main && sub_index === selected.sub ? color.primary : '',marginBottom: 15  }} >
                                {sub_item.icon}
                                <Typography style={{marginLeft: 10}}>
                                    {sub_item.name}
                                </Typography>
                            </ListItemButton>
                        )
                    }
                    </List>
                    ) : ''
                }
                {
                    index + 1 === list.length ? '' : <Divider />
                }
                </List>
            )
        }
        </>
    )
}